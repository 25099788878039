<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <!-- 1 -->
        <el-col :span="24">
          <el-col :span="16">1. È soddisfatto della sua vita?</el-col>
          <el-col :span="8">
            <el-form-item prop="q1_result">
              <el-radio-group v-model="result.q1_result">
                <el-radio :label="0" border>Si</el-radio>
                <el-radio :label="1" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 2 -->
        <el-col :span="24">
          <el-col :span="16">2. Ha abbandonato molte delle sue attività e dei suoi interessi?</el-col>
          <el-col :span="8">
            <el-form-item prop="q2_result">
              <el-radio-group v-model="result.q2_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 3 -->
        <el-col :span="24">
          <el-col :span="16">3. Ritiene che la sua vita sia vuota?</el-col>
          <el-col :span="8">
            <el-form-item prop="q3_result">
              <el-radio-group v-model="result.q3_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 4 -->
        <el-col :span="24">
          <el-col :span="16">4. Si annoia spesso?</el-col>
          <el-col :span="8">
            <el-form-item prop="q4_result">
              <el-radio-group v-model="result.q4_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 5 -->
        <el-col :span="24">
          <el-col :span="16">5. Ha speranza nel futuro?</el-col>
          <el-col :span="8">
            <el-form-item prop="q5_result">
              <el-radio-group v-model="result.q5_result">
                <el-radio :label="0" border>Si</el-radio>
                <el-radio :label="1" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 6 -->
        <el-col :span="24">
          <el-col :span="16">6. È tormentato da pensieri ce non riesce a togliersi dalla testa?</el-col>
          <el-col :span="8">
            <el-form-item prop="q6_result">
              <el-radio-group v-model="result.q6_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 7 -->
        <el-col :span="24">
          <el-col :span="16">7. È di buon umore per la maggior parte del tempo?</el-col>
          <el-col :span="8">
            <el-form-item prop="q7_result">
              <el-radio-group v-model="result.q7_result">
                <el-radio :label="0" border>Si</el-radio>
                <el-radio :label="1" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 8 -->
        <el-col :span="24">
          <el-col :span="16">8. Teme che le stia per capitare qualcosa di brutto?</el-col>
          <el-col :span="8">
            <el-form-item prop="q8_result">
              <el-radio-group v-model="result.q8_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 9 -->
        <el-col :span="24">
          <el-col :span="16">9. Si sente felice per la maggior parte del tempo?</el-col>
          <el-col :span="8">
            <el-form-item prop="q9_result">
              <el-radio-group v-model="result.q9_result">
                <el-radio :label="0" border>Si</el-radio>
                <el-radio :label="1" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 10 -->
        <el-col :span="24">
          <el-col :span="16">10. Si sente spesso indifeso?</el-col>
          <el-col :span="8">
            <el-form-item prop="q10_result">
              <el-radio-group v-model="result.q10_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 11 -->
        <el-col :span="24">
          <el-col :span="16">11. Le capita spesso di essere irrequieto e nervoso?</el-col>
          <el-col :span="8">
            <el-form-item prop="q11_result">
              <el-radio-group v-model="result.q11_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 12 -->
        <el-col :span="24">
          <el-col :span="16">12. Preferisce stare a casa, piuttosto che uscire a fare cose nuove?</el-col>
          <el-col :span="8">
            <el-form-item prop="q12_result">
              <el-radio-group v-model="result.q12_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 13 -->
        <el-col :span="24">
          <el-col :span="16">13. Si preoccupa frequentemente per il futuro?</el-col>
          <el-col :span="8">
            <el-form-item prop="q13_result">
              <el-radio-group v-model="result.q13_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 14 -->
        <el-col :span="24">
          <el-col
            :span="16"
          >14. Pensa di avere più problemi di memoria ella maggior parte delle persone?</el-col>
          <el-col :span="8">
            <el-form-item prop="q14_result">
              <el-radio-group v-model="result.q14_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 15 -->
        <el-col :span="24">
          <el-col :span="16">15. Ha speranza nel futuro?</el-col>
          <el-col :span="8">
            <el-form-item prop="q15_result">
              <el-radio-group v-model="result.q15_result">
                <el-radio :label="0" border>Si</el-radio>
                <el-radio :label="1" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 16 -->
        <el-col :span="24">
          <el-col :span="16">16. Si sente spesso abbattuto e triste, adesso?</el-col>
          <el-col :span="8">
            <el-form-item prop="q16_result">
              <el-radio-group v-model="result.q16_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 17 -->
        <el-col :span="24">
          <el-col :span="16">17. Trova che la sua condizione attuale sia degna di essere vissuta?</el-col>
          <el-col :span="8">
            <el-form-item prop="q17_result">
              <el-radio-group v-model="result.q17_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 18 -->
        <el-col :span="24">
          <el-col :span="16">18. Si tormenta molto pensando al passato?</el-col>
          <el-col :span="8">
            <el-form-item prop="q18_result">
              <el-radio-group v-model="result.q18_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 19 -->
        <el-col :span="24">
          <el-col :span="16">19. Trova che la vita sia molto eccitante?</el-col>
          <el-col :span="8">
            <el-form-item prop="q19_result">
              <el-radio-group v-model="result.q19_result">
                <el-radio :label="0" border>Si</el-radio>
                <el-radio :label="1" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 20 -->
        <el-col :span="24">
          <el-col :span="16">20. Si sente spesso indifeso?</el-col>
          <el-col :span="8">
            <el-form-item prop="q20_result">
              <el-radio-group v-model="result.q20_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 21 -->
        <el-col :span="24">
          <el-col :span="16">21. Si sente pieno di energia?</el-col>
          <el-col :span="8">
            <el-form-item prop="q21_result">
              <el-radio-group v-model="result.q21_result">
                <el-radio :label="0" border>Si</el-radio>
                <el-radio :label="1" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 22 -->
        <el-col :span="24">
          <el-col :span="16">22. Pensa di essere in una situazione priva di speranza?</el-col>
          <el-col :span="8">
            <el-form-item prop="q22_result">
              <el-radio-group v-model="result.q22_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 23 -->
        <el-col :span="24">
          <el-col
            :span="16"
          >23. Pensa che la maggior parte delle persone sia in una condizione migliore della sua?</el-col>
          <el-col :span="8">
            <el-form-item prop="q23_result">
              <el-radio-group v-model="result.q23_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 24 -->
        <el-col :span="24">
          <el-col :span="16">24. Le capita spesso di turbarsi per cose poco importanti?</el-col>
          <el-col :span="8">
            <el-form-item prop="q24_result">
              <el-radio-group v-model="result.q24_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 25 -->
        <el-col :span="24">
          <el-col :span="16">25. Ha frequentemente voglia di piangere?</el-col>
          <el-col :span="8">
            <el-form-item prop="q25_result">
              <el-radio-group v-model="result.q25_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 26 -->
        <el-col :span="24">
          <el-col :span="16">26. Ha difficoltà a concentrarsi?</el-col>
          <el-col :span="8">
            <el-form-item prop="q26_result">
              <el-radio-group v-model="result.q26_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 27 -->
        <el-col :span="24">
          <el-col :span="16">27. Si alza con piacere la mattina?</el-col>
          <el-col :span="8">
            <el-form-item prop="q27_result">
              <el-radio-group v-model="result.q27_result">
                <el-radio :label="0" border>Si</el-radio>
                <el-radio :label="1" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 28 -->
        <el-col :span="24">
          <el-col :span="16">28. Preferisce evitare gli incontri sociali</el-col>
          <el-col :span="8">
            <el-form-item prop="q28_result">
              <el-radio-group v-model="result.q28_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 29 -->
        <el-col :span="24">
          <el-col :span="16">29. Le riesce facile prendere delle decisioni?</el-col>
          <el-col :span="8">
            <el-form-item prop="q29_result">
              <el-radio-group v-model="result.q29_result">
                <el-radio :label="0" border>Si</el-radio>
                <el-radio :label="1" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 30 -->
        <el-col :span="24">
          <el-col :span="16">30. Ha la mente lucida come prima?</el-col>
          <el-col :span="8">
            <el-form-item prop="q30_result">
              <el-radio-group v-model="result.q30_result">
                <el-radio :label="0" border>Si</el-radio>
                <el-radio :label="1" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Gravitá della depressione">
              <el-input v-model="totalDescription" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template>
<script>
import TestActions from "../components/TestActions";

export default {
  name: "Gds",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 30
      },
      result: {},
      testRules: {
        q1_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q2_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q3_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q4_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q5_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q6_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q7_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q8_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q9_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q10_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q11_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q12_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q13_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q14_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q15_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q16_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q17_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q18_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q19_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q20_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q21_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q22_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q23_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q24_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q25_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q26_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q27_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q28_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q29_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q30_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ]
      }
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          return false;
        }
      });
    },
    filterResults() {
      return _.pickBy(this.result, (_, key) => key.endsWith("result"));
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
    totalDescription() {
      return _.cond([
        [
          _.conforms({ total: n => _.inRange(n, 0, 11) }),
          _.constant("Depressione assente")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 11, 17) }),
          _.constant("Depressione lieve moderata")
        ],
        [
          _.conforms({ total: n => _.gte(n, 17) }),
          _.constant("Depressione grave")
        ]
      ])(this.form);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(this.filterResults());
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.bottom-space {
  margin-bottom: 50px;
}
</style>
