<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-divider content-position="center">Screening</el-divider>

      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q1_result">
                <el-row>
                  <el-col :span="14">
                    <el-form-item
                      label="A. Presenta una perdita dell' appetito? Ha mangiato meno negli ultimi 3 mesi? (perdita d'appetito, problemi digestivi, difficoltà di masticazione o deglutizione)"
                      required
                    />
                  </el-col>

                  <el-col :span="10">
                    <el-radio-group v-model="result.q1_result">
                      <el-form-item>
                        <el-radio :label="0">grave riduzione dell'assunzione di cibo</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="1">moderata riduzione dell'assunzione di cibo</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="2">nessuna riduzione dell'assunzione di cibo</el-radio>
                      </el-form-item>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q2_result">
                <el-row>
                  <el-col :span="14">
                    <el-form-item label="B. Perdita di peso recente (<3 mesi)" required />
                  </el-col>
                  <el-col :span="10">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q2_result">
                            <el-form-item>
                              <el-radio :label="0">perdita di peso &gt; 3 kg</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">non sa</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">perdita di peso tra 1 e 3 kg</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">nessuna perdita di peso</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q3_result">
                <el-row>
                  <el-col :span="14">
                    <el-form-item label="C. Motricità" required />
                  </el-col>
                  <el-col :span="10">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q3_result">
                            <el-form-item>
                              <el-radio :label="0">dal letto alla poltrona</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">autonomo a domicilio</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">esce di casa</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q4_result">
                <el-row>
                  <el-col :span="14">
                    <el-form-item
                      label="D. Nell’ arco degli ultimi 3 mesi: malattie acute o stress psicologici?"
                      required
                    />
                  </el-col>
                  <el-col :span="10">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q4_result">
                            <el-form-item>
                              <el-radio :label="0">sì</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">no</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q5_result">
                <el-row>
                  <el-col :span="14">
                    <el-form-item label="E. Problemi neuropsicologici" required />
                  </el-col>
                  <el-col :span="10">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q5_result">
                            <el-form-item>
                              <el-radio :label="0">demenza o depressione grave</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">demenza moderata</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">nessun problema psicologico</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q6_result">
                <el-row>
                  <el-col :span="14">
                    <el-form-item
                      label="F. Indice di massa corporea (IMC = peso / (altezza)2 in kg/ m2)"
                      required
                    />
                  </el-col>
                  <el-col :span="10">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q6_result">
                            <el-form-item>
                              <el-radio :label="0">IMC &lt; 19</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">19 &le; IMC &lt; 21</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">21 &le; IMC &lt; 23</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">IMC &ge; 23</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-row type="flex" justify="end" align="center">
                <p>Valutazione di screening</p>
                <span class="partial">{{ totalA }}</span>
              </el-row>
            </el-col>
          </el-col>
        </el-col>
      </el-row>
      <div v-if="needGlobal">
        <el-divider content-position="center">Valutazione globale</el-divider>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item prop="q7_result">
                  <el-row>
                    <el-col :span="14">
                      <el-form-item label="G. Il paziente vive autonomamente a domicilio?" required />
                    </el-col>

                    <el-col :span="10">
                      <el-radio-group v-model="result.q7_result">
                        <el-form-item>
                          <el-radio :label="1">sí</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="0">no</el-radio>
                        </el-form-item>
                      </el-radio-group>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider />
              </el-col>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item prop="q8_result">
                  <el-row>
                    <el-col :span="14">
                      <el-form-item label="H. Prende più di 3 medicinali al giorno?" />
                    </el-col>
                    <el-col :span="10">
                      <el-row>
                        <el-col>
                          <div>
                            <el-radio-group v-model="result.q8_result">
                              <el-form-item>
                                <el-radio :label="0">sí</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="1">no</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider />
              </el-col>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item prop="q9_result">
                  <el-row>
                    <el-col :span="14">
                      <el-form-item label="I. Presenza di decubiti, ulcere cutanee?" />
                    </el-col>
                    <el-col :span="10">
                      <el-row>
                        <el-col>
                          <div>
                            <el-radio-group v-model="result.q9_result">
                              <el-form-item>
                                <el-radio :label="0">sí</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="1">no</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider />
              </el-col>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item prop="q10_result">
                  <el-row>
                    <el-col :span="14">
                      <el-form-item label="J. Quanti pasti completi prende al giorno?" />
                    </el-col>
                    <el-col :span="10">
                      <el-row>
                        <el-col>
                          <div>
                            <el-radio-group v-model="result.q10_result">
                              <el-form-item>
                                <el-radio :label="0">1 pasto</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="1">2 pasti</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="2">3 pasti</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider />
              </el-col>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="K. Consuma ?" />
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-col :span="20" :offset="1">
                    <el-row>
                      <el-col :span="16">
                        <el-form-item
                          label="Almeno una volta al giorno dei prodotti lattiero-caseari?"
                        />
                      </el-col>
                      <el-col :span="8">
                        <el-row>
                          <el-col>
                            <div>
                              <el-form-item prop="q11_a_result">
                                <el-radio-group v-model="result.q11_a_result">
                                  <el-form-item>
                                    <el-radio :label="1">Si</el-radio>
                                    <el-radio :label="0">No</el-radio>
                                  </el-form-item>
                                </el-radio-group>
                              </el-form-item>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-col>

                <el-col :span="24">
                  <el-col :span="20" :offset="1">
                    <el-row>
                      <el-col :span="16">
                        <el-form-item label="Una o due volte la settimana uova o legumi?" />
                      </el-col>
                      <el-col :span="8">
                        <el-row>
                          <el-col>
                            <div>
                              <el-form-item prop="q11_b_result">
                                <el-radio-group v-model="result.q11_b_result">
                                  <el-form-item>
                                    <el-radio :label="1">Si</el-radio>
                                    <el-radio :label="0">No</el-radio>
                                  </el-form-item>
                                </el-radio-group>
                              </el-form-item>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-col>

                <el-col :span="24">
                  <el-col :span="20" :offset="1">
                    <el-row>
                      <el-col :span="16">
                        <el-form-item label="Ogni giorno della carne, del pesce o del pollame?" />
                      </el-col>
                      <el-col :span="8">
                        <el-row>
                          <el-col>
                            <div>
                              <el-form-item prop="q11_c_result">
                                <el-radio-group v-model="result.q11_c_result">
                                  <el-form-item>
                                    <el-radio :label="1">Si</el-radio>
                                    <el-radio :label="0">No</el-radio>
                                  </el-form-item>
                                </el-radio-group>
                              </el-form-item>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-col>
              </el-row>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item prop="q12_result">
                  <el-row>
                    <el-col :span="14">
                      <el-form-item
                        label="L. Consuma almeno due volte al giorno frutta o verdura?"
                      />
                    </el-col>
                    <el-col :span="10">
                      <el-row>
                        <el-col>
                          <div>
                            <el-radio-group v-model="result.q12_result">
                              <el-form-item>
                                <el-radio :label="0">no</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="1">sí</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider />
              </el-col>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item prop="q13_result">
                  <el-row>
                    <el-col :span="14">
                      <el-form-item
                        label="M. Quanti bicchieri beve al giorno? (acqua, succhi, caffé, té, latte…)"
                      />
                    </el-col>
                    <el-col :span="10">
                      <el-row>
                        <el-col>
                          <div>
                            <el-radio-group v-model="result.q13_result">
                              <el-form-item>
                                <el-radio :label="0.0">meno di 3 bicchieri</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="0.5">da 3 a 5 bicchieri</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="1.0">più di 5 bicchieri</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider />
              </el-col>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item prop="q14_result">
                  <el-row>
                    <el-col :span="14">
                      <el-form-item label="N. Come si nutre?" />
                    </el-col>
                    <el-col :span="10">
                      <el-row>
                        <el-col>
                          <div>
                            <el-radio-group v-model="result.q14_result">
                              <el-form-item>
                                <el-radio :label="0">necessita di assistenza</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="1">autonomamente con difficoltà</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="2">autonomamente senza difficoltà</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider />
              </el-col>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item prop="q15_result">
                  <el-row>
                    <el-col :span="14">
                      <el-form-item
                        label="O. Il paziente si considera ben nutrito? (ha dei problemi nutrizionali)"
                      />
                    </el-col>
                    <el-col :span="10">
                      <el-row>
                        <el-col>
                          <div>
                            <el-radio-group v-model="result.q15_result">
                              <el-form-item>
                                <el-radio :label="0">malnutrizione grave</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="1">malnutrizione moderata o non sa</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="2">nessun problema nutrizionale</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider />
              </el-col>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item prop="q16_result">
                  <el-row>
                    <el-col :span="14">
                      <el-form-item
                        label="P. Il paziente considera il suo stato di salute migliore o peggiore di altre persone della sua età?"
                      />
                    </el-col>
                    <el-col :span="10">
                      <el-row>
                        <el-col>
                          <div>
                            <el-radio-group v-model="result.q16_result">
                              <el-form-item>
                                <el-radio :label="0.0">meno buono</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="0.5">non sa</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="1.0">uguale</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="2.0">migliore</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider />
              </el-col>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item prop="q17_result">
                  <el-row>
                    <el-col :span="14">
                      <el-form-item label="Q. Circonferenza brachiale (CB, cm)" />
                    </el-col>
                    <el-col :span="10">
                      <el-row>
                        <el-col>
                          <div>
                            <el-radio-group v-model="result.q17_result">
                              <el-form-item>
                                <el-radio :label="0.0">CB &lt; 21</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="0.5">CB &le; 21 CB &le; 22</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="1.0">CB &gt; 22</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider />
              </el-col>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item prop="q18_result">
                  <el-row>
                    <el-col :span="14">
                      <el-form-item label="R. Circonferenza del polpaccio (CP in cm)" />
                    </el-col>
                    <el-col :span="10">
                      <el-row>
                        <el-col>
                          <div>
                            <el-radio-group v-model="result.q18_result">
                              <el-form-item>
                                <el-radio :label="0">CP &lt; 31</el-radio>
                              </el-form-item>
                              <el-form-item>
                                <el-radio :label="1">CP &ge; 31</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-row type="flex" justify="end" align="center">
                  <p>Valutazione globale (max. 16 punti)</p>
                  <span class="partial">{{ totalB }}</span>
                </el-row>
              </el-col>
            </el-col>
          </el-col>
        </el-row>
      </div>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Valutazione dello stato nutrizionale">
              <el-input v-model="totalDescription" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template>
<script>
import { rules, filterResults, createResults, totalOf } from "../constants";
import TestActions from "../components/TestActions";

const custom = ["q11_a_result", "q11_b_result", "q11_c_result"];

export default {
  name: "Mnae",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 30
      },
      result: {},
      needGlobal: false,
      testRules: rules(18),
      aResults: createResults(1, 7),
      bResults: createResults(7, 19),
      totalA: 0,
      totalB: 0
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_screening: this.totalA,
            total_global: this.totalB,
            total_description: this.totalDescription
          };

          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    computePartialResults(reset) {
      this.totalA = totalOf(this.aResults, this.result);
      this.needGlobal = _.inRange(this.totalA, 0, 12);
      this.totalB = totalOf(this.bResults, this.result);
    },
    computeQ11() {
      let map = {
        "0": 0.0,
        "1": 0.0,
        "2": 0.5,
        "3": 1.0
      };

      let groupValues = _.values(filterResults(this.result, custom));
      if (_.size(groupValues) == 0) return;
      let ones = _.size(groupValues.filter(x => x === 1));
      return map[ones];
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
      this.needGlobal = false;
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
    totalDescription() {
      let condScreening = _.cond([
        [
          _.conforms({ total: n => _.inRange(n, 0, 8) }),
          _.constant("Malnutrito")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 8, 12) }),
          _.constant("A rischio di malnutrizione")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 12, 15) }),
          _.constant("Stato nutrizionale normale")
        ]
      ]);
      let condAll = _.cond([
        [
          _.conforms({ total: n => _.lt(n, 17) }),
          _.constant("Cattivo stato nutrizionale")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 24, 31) }),
          _.constant("Stato nutrizionale normale")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 17, 24) }),
          _.constant("Rischio di malnutrizione")
        ],
        [
          _.conforms({ total: n => _.lt(n, 17) }),
          _.constant("Cattivo stato nutrizionale")
        ]
      ]);
      let cond = this.needGlobal ? condAll : condScreening;
      return cond(this.form);
    }
  },
  watch: {
    result: {
      handler: function() {
        this.result.q11_result = this.computeQ11();
        this.computePartialResults();
        let results = _.values(filterResults(this.result, null, ['q11_a_result', 'q11_b_result', 'q11_c_result']));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.radio-label {
  white-space: normal;
}
.radio-label_text {
  margin-left: 20px;
}
.partial {
  font-weight: bold;
  margin-left: 10px;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>
