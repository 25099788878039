<template>
  <div>
    <!-- modale per somministrazione -->
    <md-dialog
      :md-active.sync="showDialog"
      @md-clicked-outside="close"
      :class="modalType == 'bisogno' ? 'bisogno-therapies' : ''"
    >
      <md-dialog-title>{{
        modalType == "abitudinale"
          ? "Somministra Terapie"
          : "Somministra Terapia al Bisogno/Lassativa"
      }}</md-dialog-title>
      <el-form
        label-position="right"
        label-width="80px"
        :model="form"
        :rules="rules"
        ref="form"
      >
        <el-row :gutter="20" v-if="modalType == 'bisogno'">
          <el-col :span="24">
            <el-form-item label="Ospite" prop="patient_id">
              <el-select
                v-model="form.patient_id"
                filterable
                placeholder="Seleziona Ospite"
                clearable
                @clear="showAbsentPatientAlert = false"
                @change="newPrescriptionPatient"
              >
                <el-option
                  v-for="item in patients"
                  :key="item.id"
                  :label="item.full_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span v-if="showAbsentPatientAlert">
                <font-awesome-icon icon="exclamation" class="danger-text" />
                <font style="color: red"
                  >Attenzione: l'ospite è attualmente assente</font
                >
              </span>
              <span v-if="showOldAbsencePatientAlert">
                <font-awesome-icon icon="exclamation" class="danger-text" />
                <font style="color: red"
                    >Attenzione: l'ospite era assente in questo periodo</font
                >
            </span>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.patient_id">
            <el-table
              :data="bisognoTherapies"
              style="width: 100%; margin-bottom: 15px"
              @selection-change="handleTherapyBisognoSelection"
              :row-class-name="rowClass"
              :default-expand-all="true"
              v-loading="loading"
              :height="350"
            >
              <el-table-column type="expand" width="50">
                <template slot-scope="props">
                  Ultime somministrazioni:
                  <el-tag
                    v-for="(item, index) in _.slice(
                      props.row.last_therapy_subministration,
                      0,
                      4
                    )"
                    :key="index"
                    type="info"
                  >
                    {{ item.clean_date_time }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column
                label="Principio Attivo"
                prop="principio_attivo"
                min-width="230"
              ></el-table-column>
              <el-table-column
                label="Farmaco"
                prop="farmaco_name"
                min-width="280"
              ></el-table-column>
              <el-table-column
                label="Q.tà"
                prop="quantity"
                min-width="60"
              ></el-table-column>
              <el-table-column
                label="Giorno"
                prop="weekday"
                min-width="80"
              ></el-table-column>
              <el-table-column
                label="Indicazioni"
                prop="description"
                min-width="200"
              ></el-table-column>

              <el-table-column min-width="200" label="Bisogno" sortable>
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 2">{{
                    scope.row.bisogno
                  }}</span>
                  <span v-if="scope.row.type == 3"
                    >Se non evacua da {{ scope.row.every }} giorni</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="Somministrazione"
                prop="subministration_type_description"
                width="150"
              ></el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Data" prop="date">
              <el-date-picker
                v-model="form.date"
                :editable="false"
                type="date"
                placeholder="Scegli data"
                :picker-options="{
                  firstDayOfWeek: 1,
                  disabledDate(time) {
                    return time.getTime() > Date.now();
                  },
                }"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Orario" prop="time">
              <el-time-picker
                v-model="form.time"
                :editable="false"
                format="HH:mm"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="{
                  selectableRange: selectableTime,
                }"
                placeholder="Seleziona Orario"
              >
              </el-time-picker>
            </el-form-item>
          </el-col>
          <el-col
            :span="24"
            class="text-center"
            v-if="modalType == 'abitudinale'"
          >
            <el-form-item label="Eseguito" prop="verification_result">
              <el-radio v-model="form.verification_result" :label="1" border
                >Sì</el-radio
              >
              <el-radio v-model="form.verification_result" :label="2" border
                >No</el-radio
              >
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Note" prop="description">
              <el-input type="textarea" :rows="2" v-model="form.description">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <span v-if="showAbsentPatientAlert && modalType == 'abitudinale'">
              <font-awesome-icon icon="exclamation" class="danger-text" />
              <font style="color: red"
                >Attenzione: l'ospite è attualmente assente</font
              >
            </span>
            <span v-if="showOldAbsencePatientAlert && modalType == 'abitudinale'">
                <font-awesome-icon icon="exclamation" class="danger-text" />
                <font style="color: red"
                    >Attenzione: l'ospite era assente in questo periodo</font
                >
            </span>
          </el-col>
          <el-col :span="24">
            <span
              v-if="
                checkDifferenceFourHours && this.selectedTherapies.length > 0
              "
            >
              <font-awesome-icon icon="exclamation" class="danger-text" />
              <font style="color: red"
                >Attenzione: Si sta procedendo a somministrare una o più terapie
                con oltre 4 ore di differenza, continuare?
              </font>
            </span>
          </el-col>
        </el-row>
      </el-form>
      <md-dialog-actions>
        <el-button type="info" plain @click="close">Annulla</el-button>
        <el-button
          type="success"
          plain
          @click="submit()"
          :loading="submitLoading"
          :disabled="modalType == 'bisogno' && !selected"
          >Salva</el-button
        >
      </md-dialog-actions>
    </md-dialog>

    <el-button
      class="add-button to-sx"
      type="success"
      @click="somministra('abitudinale')"
      v-if="selected"
      >Somministra</el-button
    >
    <el-button
      class="add-button"
      @click="somministra('bisogno')"
      type="success"
      icon="el-icon-plus"
      round
    ></el-button>
    <!-- fine pulsanti header -->
    <el-row :gutter="20" class="no-margin-row">
      <!-- select orario -->
      <el-col :span="24">
        <el-select
          v-model="subministrationTimesForm"
          placeholder="Seleziona orario"
          clearable
          ref="orario"
          @clear="onTimeReset"
        >
          <!-- multiple -->
          <el-option
            v-for="(item, index) in departmentSubministrationTimes"
            :key="index"
            :label="item.label"
            :value="index"
            :style="{ color: differenceFourHours(item.label) ? 'red' : '' }"
          >
          </el-option>
        </el-select>
      </el-col>

      <!-- somministrazioni -->
      <el-col
        :span="24"
        style="margin-top: 15px"
        v-if="filteredPatients && filteredPatients.length > 0"
      >
        <el-button
          type="primary"
          :icon="collapseTab ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
          circle
          style="margin: 5px 18px"
          @click="collapseTab ? (collapseTab = false) : (collapseTab = true)"
          size="mini"
          v-show="!loadingTabs"
        ></el-button>
        <el-tabs
          tab-position="left"
          class="subministration-tabs"
          @tab-click="changePatient"
          v-model="activePatientTab"
          v-loading="loadingTabs"
        >
          <el-tab-pane
            v-for="(patient, index) in filteredPatients"
            :key="index"
            :label="patient.full_name + patient.absence_info.absence_status"
            :value="patient.id"
            v-loading="loading"
            style="margin-bottom: 20px"
            :lazy="true"
          >
            <span
              slot="label"
              :class="{
                completed:
                  patient['subministrationProgressStatus'] &&
                  patient['subministrationProgressStatus'][
                    subministrationTimesForm
                  ],
              }"
            >
              <span
                v-if="
                  patient['subministrationProgressStatus'] &&
                  patient['subministrationProgressStatus'][
                    subministrationTimesForm
                  ]
                "
                ><i class="el-icon-success completed"></i
              ></span>
              <span v-show="!collapseTab">{{ patient.full_name }}</span>
              <span v-show="collapseTab">{{
                patient.surname.slice(0, 1) + patient.name.slice(0, 1)
              }}</span>
            </span>
            <!-- somministrazione -->
            <div
              class="therapieswrapper"
              v-loading="loading"
              style="padding-bottom: 20px"
            >
              <el-row :gutter="20">
                <el-col :span="24" style="margin-bottom: 20px">
                  <h2 :class="{ absence: patient.absence_info.absence_status }">
                    <center>{{ patient.full_name }}</center>
                  </h2>
                  <span class="absence" v-if="patient.absence_info.absence_status"
                    ><center>Paziente Assente</center></span
                  >
                </el-col>
                <el-col :span="12">
                  <el-image
                    :src="`/app/public/${image_path}`"
                    style="width: 180px; height: 180px"
                  ></el-image>
                </el-col>
                <el-col :span="12">
                  <b>Allergie:</b>
                  <p>{{ allergie }}</p>
                </el-col>
                <!-- terapia abitudinale -->
                <el-col :span="24" class="text-center">
                  <h2>Terapia Abitudinaria</h2>
                </el-col>
                <el-col :span="24">
                  <el-table
                    :data="therapiesTable"
                    style="width: 100%; margin-bottom: 15px"
                    :default-sort="{ prop: 'clean_orario', order: 'ascending' }"
                    @selection-change="handleTherapySelection"
                    :row-class-name="rowClass"
                  >
                    <el-table-column
                      type="selection"
                      width="55"
                      fixed
                      :selectable="selectable"
                    ></el-table-column>
                    <el-table-column label="Farmaco" min-width="280">
                      <template slot-scope="scope">
                        {{ scope.row.therapy.farmaco_name }}
                      </template>
                    </el-table-column>
                    <el-table-column label="Principio Attivo" min-width="230">
                      <template slot-scope="scope">
                        {{ scope.row.therapy.principio_attivo }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Quantità"
                      prop="quantity"
                      min-width="80"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.therapy.quantity }}
                      </template>
                    </el-table-column>
                    <el-table-column label="Somministrazione" min-width="150">
                      <template slot-scope="scope">
                        {{ scope.row.therapy.subministration_type_description }}
                      </template>
                    </el-table-column>
                    <el-table-column label="Indicazioni" min-width="200">
                      <template slot-scope="scope">
                        {{ scope.row.therapy.description }}
                      </template>
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import moment from "moment";
import Subministration from "@/js/api/services/Subministration";
import axios from "axios";

export default {
  name: "Subministrations",
  props: ["unvalidated"],
  data() {
    return {
      loading: false,
      submitLoading: false,
      subministrations: [],

      orario: null,

      therapies: [],
      bisognoTherapies: [],
      subministration_times: [],

      allergie: null,
      image_path: null,

      selectedTherapies: [],
      selectedBisognoTherapies: [],

      showDialog: false,
      form: {
        date: new Date(),
        time: new Date(),
      },
      routeParams: null,
      cancelRequest: null,
      showAbsentPatientAlert: false,
      showOldAbsencePatientAlert: false,
      departmentSubministrationTimes: {},
      subministrationTimesForm: null, // in caso di select multiorario :{},
      filteredPatients: null,
      selectedPatient: null,
      patient: null,
      activePatientTab: "0",
      collapseTab: false,
      loadingTabs: false,
      start: null,
      modalType: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    selected() {
      return (
        this.selectedTherapies.length > 0 ||
        this.selectedBisognoTherapies.length > 0
      );
    },
    selectableTime() {
      var endTime = new moment();
      var startTime = moment(endTime).add(-4, "hours").format("HH:mm:ss");
      return startTime + " - " + endTime.format("HH:mm:ss");
    },
    therapiesTable() {
      if (this.subministrationTimesForm) {
        var self = this;
        return _.filter(this.therapies, (record) => {
          // recupero solo le terapie che corrispondono all'orario selezionato
          if (record.orario !== self.subministrationTimesForm) return false;

          if (record.therapy.verification_result != null) {
            // se una terapia è stata chiusa nell'arco della giornata ma era già stata somministrata nell'ora selezionata,
            // allora deve apparire
            return (
              moment(record.therapy.updated_at).format("YYYY-MM-DD HH:mm:ss") >=
              moment().format("YYYY-MM-DD " + self.subministrationTimesForm)
            );
          } else if (
            // se una terapia è stata aperta nell'arco della giornata,
            // allora deve apparire nell'ora selezionata se la data di creazione è antecedente all'orario
            moment(record.therapy.created_at).format("YYYY-MM-DD") ==
            moment().format("YYYY-MM-DD")
          ) {
            return (
              moment(record.therapy.created_at).format("YYYY-MM-DD HH:mm:ss") <=
              moment().format("YYYY-MM-DD " + self.subministrationTimesForm)
            );
          }

          return true;
        });
      } else {
        return this.therapies;
      }
    },
    checkDifferenceFourHours() {
      var time = moment(this.form.time).format("HH:mm");
      return (
        Math.abs(
          moment(time, "HH:mm").diff(
            moment(this.subministrationTimesForm, "HH:mm"),
            "hours",
            true
          )
        ) > 4
      );
    },
    patients() {
      return this.$store.state.patients;
    },
    rules() {
      return {
        patient_id: [
          {
            required: this.modalType == "bisogno" ? true : false,
            message: "Inserire Orario",
            trigger: "blur",
          },
        ],
        bisogno_therapy_id: [
          {
            required: this.modalType == "bisogno" ? true : false,
            message: "Selezionare Terapia",
            trigger: "blur",
          },
        ],
        time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
        date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
        verification_result: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" },
        ],
        description: [
          {
            required: this.form.verification_result == 2 ? true : false,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
      };
    },
  },
  methods: {
    onTimeReset() {
      this.subministrationTimesForm = null;
      this.filteredPatients = null;
      this.selectedPatient = null;
      this.activePatientTab = "0";
      this.showAbsentPatientAlert = false;
      this.selectedTherapies = [];
    },
    async fetch() {
      this.loading = true;

      this.allergie = null;
      this.image_path = null;
      this.subministration_times = [];
      this.bisognoTherapies = [];
      this.therapies = [];

      const patient_id = this.selectedPatient;

      let params = {
        patient_id: patient_id,
        selected_time: this.subministrationTimesForm,
        type: this.modalType == "bisogno" ? "bisogno" : "abitudinale",
      };
      this.checkAbsence();

      this.cancelFetch();
      this.cancelRequest = axios.CancelToken.source();

      try {
        const response = await Subministration.fetch(params, {
          cancelToken: this.cancelRequest.token,
        });
        this.cancelRequest = null;
        this.allergie = response.data.allergie;
        this.image_path = response.data.image_path;

        this.subministration_times = [];
        this.bisognoTherapies = response.data.bisogno_therapies;
        this.therapies = response.data.therapies;

        const { time } = this.$route.query;
        this.applyTime(time);
      } catch (e) {
        console.error(e);
      } finally {
        if(this.modalType !== 'bisogno'){
            this.setHeightTabPane();
        }
        this.loading = false;
      }
    },
    applyTime(time) {
      if (!time) {
        this.orario = _.keys(this.departmentSubministrationTimes)[0];
        return;
      }
      this.orario = _.take(time.split(":"), 2).join(":");
    },
    somministra(type) {
      this.showDialog = true;
      this.modalType = type;
      if (this.modalType == "bisogno") {
        this.onTimeReset();
      }
    },
    differenceFourHours(time) {
      const bool =
        Math.abs(moment().diff(moment(time, "HH:mm"), "hours", true)) > 4;
      if (this.$refs.orario) {
        if (parseInt(this.$refs.orario.selected.value) == parseInt(time)) {
          if (!bool) {
            this.$refs.orario.$el.querySelectorAll(
              ".el-input--suffix .el-input__inner"
            )[0].style.color = "";
          } else {
            this.$refs.orario.$el.querySelectorAll(
              ".el-input--suffix .el-input__inner"
            )[0].style.color = "#ff5252";
          }
        }
      }

      return bool;
    },
    checkAbsence() {
      this.showAbsentPatientAlert = false;
      if (this.selectedPatient) {
        if (this.modalType == "bisogno") {
          this.patient = this.patients.filter(
            (x) => x.id == this.selectedPatient
          )[0];
        } else {
          this.patient = this.filteredPatients.filter(
            (x) => x.id == this.selectedPatient
          )[0];
        }
        if (this.patient.absence_info.absence_status) {
          this.showAbsentPatientAlert = true;
        }
      }
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.store();
        } else {
          return false;
        }
      });
    },
    async store() {
      this.submitLoading = true;
      let params = {
        therapies: this.selectedTherapies,
        bisognoTherapies: this.selectedBisognoTherapies,

        date: moment(this.form.date).format("YYYY-MM-DD"),
        time: moment(this.form.time).format("HH:mm:ss"),
        description: this.form.description,
        verification_result:
          this.modalType == "abitudinale" ? this.form.verification_result : 1,
      };
      try {
        const response = await Subministration.store(params);
        if (this.modalType == "abitudinale") this.fetch();
        this.close();
      } catch (error) {
        this.submitLoading = false;
      }
    },
    handleTherapySelection(val) {
      this.selectedTherapies = val;
    },
    handleTherapyBisognoSelection(val) {
      this.selectedBisognoTherapies = val;
    },
    rowClass({ row, rowIndex }) {
      if (row.is_subministrated == 1 || row.is_given == 1) {
        return "success-row";
      } else if (row.is_subministrated == 2) {
        return "danger-row";
      } else if (row.is_expired) {
        return "warning-row";
      }
      return "";
    },
    close() {
      this.form = {
        date: new Date(),
        time: new Date(),
      };
      this.showDialog = false;
      this.modalType = null;
      this.submitLoading = false;
      this.showAbsentPatientAlert = false;
      this.selectedBisognoTherapies = [];
      this.selectedTherapies = [];
    },
    changePatient(value) {
      this.selectedPatient = value.$attrs.value;
      this.fetch();
      this.collapseTab = true;
    },
    selectable(row, idx) {
      return !row.subministrated == 1;
    },
    setHeightTabPane() {
      const headerTabPane =
        document.querySelector(".subministration-tabs .el-tabs__header") ??
        null;
      const bodyTabPane =
        document.querySelector(".subministration-tabs .el-tabs__content") ??
        null;

      const bodyStyles = window.getComputedStyle(bodyTabPane);
      let bodyHeight = parseInt(bodyStyles.getPropertyValue("height"));

      headerTabPane.style.setProperty(
        "height",
        parseInt(bodyHeight - 30) + "px"
      );
    },
    cancelFetch() {
      if (this.cancelRequest) {
        this.cancelRequest.cancel("Cancel request");
      }
    },
    newPrescriptionPatient(value) {
      this.selectedPatient = value;
      this.fetch();
    },
  },
  async mounted() {
    this.start = moment();
    const respTimes = await Subministration.times(
      this.$store.state.departmentId
    );
    this.departmentSubministrationTimes = respTimes.data;

    const respStatus = await Subministration.status(
      this.$store.state.departmentId
    );
    var subministrationStatus = respStatus.data;

    _.map(this.$store.state.patients, function (patient) {
      if (subministrationStatus[patient.id]) {
        patient["subministrationProgressStatus"] =
          subministrationStatus[patient.id];
      }
    });
  },
  watch: {
    async subministrationTimesForm(value) {
      this.loadingTabs = true;
      this.activePatientTab = "0";
      if (value) {
        this.filteredPatients = null;

        let timePatients = _.uniq(
          this.departmentSubministrationTimes[value]["patients"]
        );

        this.filteredPatients = _.filter(this.patients, (patient) =>
          _.includes(timePatients, patient.id)
        );

        if (this.$route.query.time && this.$route.query.patient_id) {
          this.selectedPatient = this.$route.query.patient_id;
          await this.fetch();
          this.activePatientTab = _.findIndex(this.filteredPatients, [
            "id",
            this.selectedPatient,
          ]).toString();
          this.$router.replace({ query: null });
        } else if (this.filteredPatients.length > 0) {
          this.selectedPatient = this.filteredPatients[0].id;
          await this.fetch();
        }
      }
      this.loadingTabs = false;
    },
    "$route.query"() {
      if (this.$route.query.time) {
        this.subministrationTimesForm = _.toString(this.$route.query.time);
      }
    },
    form: {
      deep: true,
      handler(data) {
        this.showOldAbsencePatientAlert = false;
        if(!this.showAbsentPatientAlert && this.patient.absence_info.last_absence){
            var date = `${moment(this.form.date).format("YYYY-MM-DD")} ${moment(this.form.time).format("HH:mm:ss")}`;
            if (date > this.patient.absence_info.last_absence['confirmation_date'] && date < this.patient.absence_info.last_absence['verification_date']) {
                this.showOldAbsencePatientAlert = true;
            }
        }
      },
    },
  },
};
</script>
<style scoped>
.therapieswrapper {
  height: calc(100vh - 230px);
  overflow-y: scroll;
}
.absence {
  color: red;
}
.completed {
  color: #67c23a;
}
.to-sx {
  position: fixed;
  right: 68px;
  top: 75px;
}
</style>
<style lang="scss">
.md-dialog.bisogno-therapies {
  display: block;
  max-width: 98vw;
  max-height: 90vh;
  min-width: 98vw;
  min-height: 90vh;
  top: 50% !important;
  width: 98vw;
  height: 90vh;
  & tr .el-table__expanded-cell {
    padding: 5px 20px !important;
  }
}
</style>
