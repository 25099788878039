import { render, staticRenderFns } from "./Cirs.vue?vue&type=template&id=84ba2968&scoped=true&"
import script from "./Cirs.vue?vue&type=script&lang=js&"
export * from "./Cirs.vue?vue&type=script&lang=js&"
import style0 from "./Cirs.vue?vue&type=style&index=0&id=84ba2968&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84ba2968",
  null
  
)

export default component.exports