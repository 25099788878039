<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="8">
              <el-form-item label="  " />
            </el-col>
            <el-col :span="16">
              <el-row type="flex" class="row-bg" justify="center">
                <el-col>
                  <center class="head">Assente</center>
                </el-col>
                <el-col>
                  <center class="head">Lieve</center>
                </el-col>
                <el-col>
                  <center class="head">Moderato</center>
                </el-col>
                <el-col>
                  <center class="head">Grave</center>
                </el-col>

                <el-col>
                  <center class="head">Molto Grave</center>
                </el-col>
              </el-row>
              <el-divider content-position="center"></el-divider>
            </el-col>
          </el-col>

          <el-col :span="24" v-for="(item, idx) in items" :key="idx">
            <el-form-item :prop="`q${++idx}_result`">
              <el-col :span="8">
                <el-form-item :label="item" required />
              </el-col>
              <el-col :span="16">
                <el-row type="flex" justify="center">
                  <el-col v-for="opt in 5" :key="opt + '-opt'">
                    <el-col>
                      <center>
                        <el-radio :label="opt" v-model="result[`q${idx}_result`]" />
                      </center>
                    </el-col>
                  </el-col>
                </el-row>
              </el-col>
            </el-form-item>
            <el-divider content-position="center"></el-divider>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="8">
              <el-form-item label="Totale">
                <el-input v-model="form.total" :disabled="true">
                  <template slot="append">/ {{form.total_quest}}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Indice di severitá">
                <el-input v-model="total_sev" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Indice di comorbiditá">
                <el-input v-model="total_com" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template>
<script>
import { rules, filterResults, createResults } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Cirs",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 70
      },
      result: {},
      total_sev: 0,
      total_com: 0,
      testRules: rules(14),
      items: [
        "Patologie cardiache (solo cuore)",
        "Ipertensione (si valuta la severità, gli organi coinvolti sono da considerarsi separatamente)",
        "Patologie vascolari (sangue, vasi, midollo, milza, sistema linfatico)",
        "Patologie respiratorie (polmoni, bronchi, trachea sotto la laringe)",
        "O.O.N.G.L. (occhio, orecchio, naso, gola, laringe)",
        "Apparato GI superiore (esofago, stomaco, duodeno, albero biliare, pancreas)",
        "Apparato GI inferiore (intestino, ernie)",
        "Patologie epatiche (solo fegato)",
        "Patologie renali (solo rene)",
        "Altre patologie genito-urinarie (ureteri, vescica, uretra, prostata, genitali ",
        "Sistema muscolo-scheletro-cute, muscoli, scheletro, tegumenti)",
        "Patologie sistema nervoso (sistema nervoso centrale e periferico; non include la demenza)",
        "Patologie endocrine-metaboliche (include diabete, infezioni, sepsi, stati tossici)",
        "Patologie psichiatriche-comportamentali (include demenza, depressione, ansia, agitazione, psicosi)"
      ],
      toConsider: createResults(1, 14)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_sev: this.total_sev,
            total_com: this.total_com
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    computePartials() {
      let toCalculate = _.values(filterResults(this.result, this.toConsider));
      this.total_sev = _.mean(toCalculate);
      this.total_com = _.size(_.filter(toCalculate, n => _.gte(n, 3)));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        this.$refs["form"].clearValidate();
        this.computePartials();
        let results = _.values(filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 80%;
}
.collapse-header {
  font-size: 16px;
}
.head {
  padding: 4px;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>
