<template>
  <div>
    <arki-create-dialog
      v-if="user.is_chief_nurse || user.is_doctor"
      ref="dialog"
      title="Comunicazione"
      dateOptions="future"
      :rules="rules"
      action="/comunications"
      @submit="update"
    />
    <arki-broadcast ref="broadcast" listen=".comunication.created" @update="update" />
    <div>
      <el-table
        :class="paginateTable"
        :data="comunications.data"
        :default-sort="{ prop: 'time', order: 'descending' }"
        style="width: 100%;"
        :max-height="maxHeight"
        :row-class-name="rowClass"
        v-loading="loading"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-col :span="24">
              <b>Inserimento:</b> {{ scope.row.full_creator }} ({{
                professions[scope.row.profession].label
              }}) - {{ scope.row.created_at_time }}
            </el-col>
            <el-col :span="24" v-if="scope.row.enabled == 0">
              <b>Annullamento:</b>
              {{ scope.row.full_remover }}
              {{
                scope.row.updater_profession
                  ? "(" + professions[scope.row.updater_profession].label + ")"
                  : ""
              }}
              -
              {{ scope.row.removed_at_time }}
            </el-col>
            <el-col
              :span="24"
              v-if="scope.row.enabled == 1 && scope.row.updater_profession"
            >
              <b>Modifica:</b>
              {{ scope.row.full_creator }}
              {{
                scope.row.updater_profession
                  ? "(" + professions[scope.row.updater_profession].label + ")"
                  : ""
              }}
              -
              {{ scope.row.updated_at_time }}
            </el-col>
          </template>
        </el-table-column>
        <el-table-column prop="type" width="60">
          <template slot-scope="scope">
            <el-button
              circle
              size="mini"
              icon="el-icon-chat-dot-round"
              :style="{
                backgroundColor: professions[scope.row.profession].color,
              }"
            >
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="clean_date_time"
          label="Data e Ora"
          sortable
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="Titolo"
          width="170px"
        ></el-table-column>
        <el-table-column prop="message" label="Messaggio"></el-table-column>
        <el-table-column v-if="user.is_chief_nurse || user.is_doctor" prop="full_names"
            label="Elenco visualizzazioni" align="center" width="150px">
          <template slot-scope="scope">
            <arki-comunication-modal :parentScope="scope.row" :user="user" :users="users"></arki-comunication-modal>
          </template>
        </el-table-column>
        <el-table-column label="Azioni" align="center" width="108px">
          <template v-if="scope.row.enabled == 1" slot-scope="scope">
            <arki-check-dialog
              @success="update()"
              class="float-right margin-left"
              :title="`${scope.row.title}`"
              :comunication="scope.row"
              :action="`comunications/${scope.row.id}`"
              :isDeleteble="scope.row.creator_id == user.id"
              :rules="rules"
              option="comunications"
              method="patch"
              :row="scope.row"
              v-if="scope.row.creator_id == user.id && scope.row.enabled === 1"
            />
            <button v-else-if="comunicationsUnread.length > 0 &&
                comunicationsUnread.data.includes(scope.row.id) &&
                scope.row.creator_id != user.id
                "
                @click="toggleMessage(scope.row)"
            >
                <em class="el-icon-view" />
            </button>
            <div v-else>
                <i class="el-icon-check"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="comunications.per_page"
        :total="comunications.total"
        :hide-on-single-page="true"
        :current-page="current_page"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Comunication from "@/js/api/services/Comunication";
import ArkiCreateDialog from "@/js/components/ArkiCreateDialog";
import ArkiBroadcast from "@/js/components/ArkiBroadcast";
import ArkiCheckDialog from "@/js/components/ArkiCheckDialog";
import ArkiComunicationModal from './ArkiComunicationModal.vue';

export default {
  name: "ArkiComunication",
  components: {
    ArkiCreateDialog,
    ArkiBroadcast,
    ArkiCheckDialog,
    ArkiComunicationModal,
  },
  data() {
    return {
      loading: true,
      comunications: {
        data: [],
        per_page: 1,
        total: null,
      },
      users: [],
      comunicationsUnread: {
        data: [],
        length: 0,
      },
      form: {},
      current_page: 1,
      activeCommunicationId: null,
      rules: {
        date: [{ required: true, message: "Inserire data", trigger: "blur" }],
        time: [{ required: true, message: "Inserire orario", trigger: "blur" }],
        title: [
          { required: true, message: "Inserire titolo", trigger: "blur" },
        ],
        message: [
          { required: true, message: "Inserire mesaggio", trigger: "blur" },
        ],
      },
      paginateTable: null,
      // first fetch is from filter
      unchangedFilter: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    maxHeight() {
        if (this.paginateTable == null){
            return window.innerHeight - 135;
        }
        return window.innerHeight - 330;
    },
    professions() {
      return this.$store.state.professions;
    },
  },
  watch: {
    comunications: function () {
      this.comunications.total / this.comunications.per_page > 1
        ? (this.paginateTable = "paginate-table")
        : (this.paginateTable = null);
    },
  },
  methods: {
    update() {
      this.unchangedFilter = true;
      this.$refs.broadcast.reset();
      this.fetch();
    },
    async fetch() {
      this.loading = true;
      if (!this.unchangedFilter) {
        this.current_page = 1;
      }
      let params = {
        page: this.current_page,
      };
      const response = await Comunication.fetch(params);
      this.comunications = response.data.comunication;
      this.comunications.data = response.data.comunication.data.filter(comunication => {
        return !(moment(comunication.date_time).isAfter(moment()) && comunication.creator_id !== this.user.id);
      });
      this.users = response.data.users;
      const responseCount = await Comunication.count();
      this.comunicationsUnread.data = responseCount.data;
      this.comunicationsUnread.length = responseCount.data.length;
      this.loading = false;
      // reset the checked flag
      this.unchangedFilter = false;
    },
    async toggleMessage(row) {
      let params = {
        userId: this.user.id,
      };
      await Comunication.markAsSeen(row.id, params)
        .catch((error) => {
          return false;
        })
        .finally(() => {
          this.comunicationsUnread.data.splice(
            this.comunicationsUnread.data.indexOf(row.id),
            1
          );
        });
    },
    changePage(page) {
      this.current_page = page;
      this.update();
    },
    rowClass({ row, rowIndex }) {
      if (row.enabled == 0) {
        return "deleted-row";
      }
    },
  },
  mounted(){
    this.fetch();
  }
};
</script>
