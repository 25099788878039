<template>
    <div>
        <md-dialog :md-active.sync="dialogVisible" :md-click-outside-to-close="false">
            <md-dialog-title>{{ modal.title }}</md-dialog-title>
            <el-form label-position="right" label-width="80px" :model="form" ref="form">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="Label" prop="label">
                            <el-input v-model="form.label"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="modal.title == 'Aggiungi Professioni' || modal.title == 'Modifica Professioni'">
                        <el-form-item label="Color" prop="color" :rules="rules">
                            <el-input placeholder="Inserire codice colore preceduto da #" v-model="form.color">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="Attiva" prop="enabled">
                            <el-switch v-model="form.enabled" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <md-dialog-actions>
                    <el-button type="info" plain @click="closeModal()">Annulla</el-button>
                    <el-button type="success" plain @click="submitModal()" :loading="loadingSubmitModal">{{
            modal.confirm }}</el-button>
                </md-dialog-actions>
            </el-form>
        </md-dialog>

        <el-row :gutter="20">
            <el-col :span="24">
                <div class="block no-filters" v-loading="loading">
                    <el-col :span="12" v-for="(dropdown, idx) in dropdowns" :key="idx">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>{{ dropdown.title }}</span>
                                <el-button type="success" style="float: right; padding: 5px 20px" :disabled="dropdown.readonly" @click="openModal('insert', dropdown.cat, dropdown.title)">
                                    <i class="el-icon-plus"></i>
                                </el-button>
                            </div>
                            <el-table :data="dropdown.list" stripe style="width: 100%" height="286">
                                <el-table-column prop="id" label="ID" width="50"></el-table-column>
                                <el-table-column>
                                    <template slot="header">Label</template>
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.color" :style="'display:inline-block; height:1rem; width:1rem; background-color: ' + scope.row.color + '; vertical-align:middle;'"></span>
                                        {{ scope.row.label }}
                                    </template>
                                </el-table-column>
                                <el-table-column align="right" width="110">
                                    <template slot="header"></template>
                                    <template slot-scope="scope">
                                        <el-button v-if="scope.row.enabled == 1" type="success" size="mini" circle disabled></el-button>
                                        <el-button v-if="scope.row.enabled == 0" type="danger" size="mini" circle disabled></el-button>
                                        <el-button size="mini" @click="
            openModal(
                'update',
                dropdown.cat,
                dropdown.title,
                scope.row
            )
            ">Edit</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-card>
                    </el-col>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import SuperadminDropdownService from "@/js/api/services/superadmin/Dropdown";

export default {
    name: "SuperadminDropdowns",
    data() {
        return {
            dropdowns: null,
            loading: true,
            dialogVisible: false,
            itemOperation: null,
            modal: {},
            form: {
                enabled: true,
            },
            loadingSubmitModal: false,
            rules: [
                { required: true, message: "Inserisci un colore", trigger: "blur" },
                {
                    validator: (rule, value, callback) => {
                        if (!value.startsWith("#")) {
                            callback(new Error("Il colore deve iniziare con '#'"));
                        } else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ],
        };
    },
    methods: {
        async fetch() {
            this.loading = true;
            const response = await SuperadminDropdownService.get();
            this.dropdowns = response.data;
            this.loading = false;
        },
        // @params operation[insert|update]
        openModal(operation, cat, title, item = null) {
            this.form.cat = cat;
            if (item) {
                const { id, label, enabled, color } = item;
                this.form = { cat, id, label, enabled: Boolean(enabled), color };
            }
            this.itemOperation = operation;
            if (operation == "insert") {
                this.modal = {
                    title: "Aggiungi " + title,
                    confirm: "Inserisci",
                };
            } else if (operation == "update") {
                this.modal = {
                    title: "Modifica " + title,
                    confirm: "Conferma",
                };
            }
            this.dialogVisible = true;
        },
        async submitModal() {
            this.loadingSubmitModal = true;
            const isValid = await this.saveDropdown();
            this.loadingSubmitModal = false;
            if (isValid) {
                this.closeModal();
            }
        },
        async saveDropdown() {
            const { enabled } = this.form;
            let form = {
                ...this.form,
                enabled: +enabled,
            };
            let isValid = await new Promise(resolve => {
                this.$refs.form.validate(valid => resolve(valid));
            });
            if (!isValid) {
                this.$message({
                    type: "warning",
                    message: "Impostare colore!"
                });
                return false;
            }
            if (this.itemOperation === "insert") {
                await SuperadminDropdownService.store(form);
            } else if (this.itemOperation === "update") {
                await SuperadminDropdownService.update(form);
            }
            await this.fetch();
            return true;
        },
        closeModal() {
            this.dialogVisible = false;
            this.$refs["form"].resetFields();
            this.form = {
                enabled: true,
            };
            this.modal = {};
            this.itemOperation = null;
        },
    },
    mounted() {
        this.fetch();
    },
};
</script>

<style lang="scss" scoped>
.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both;
}

.box-card {
    width: 100%;
    margin: 10px 0;
}

.circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-left: 8px;
}
</style>
