<script>
import SelectParticipation from "../filters/SelectParticipation.vue";
import { ActivityEntry } from "@/js/api/services/activity";
import CreateActivityEntry from "./CreateActivityEntry.vue";

export default {
  name: "ShowActivityEntries",
  components: { SelectParticipation, CreateActivityEntry },
  props: ["visible", "selected"],
  data() {
    return {
      loading: false,
      err: null,
      form: {},
      selectedEntry: null,
      showCreateEntryDialog: false,
      loadingCancel: false,
    };
  },
  computed: {
    entries() {
      if (!this.selected) return [];
      const { row, day } = this.selected || {};
      const xs = (row.entries || []).filter((x) => x.day == day);
      return xs;
    },
  },
  methods: {
    getParticipation(row) {
      const found = (row.tags || []).find(
        (t) => t.lookup.code == "tipo-partecipazione"
      );
      return found ? found.label : "";
    },
    async handleDelete(idx, row) {
      if (!this.selected) return;

      this.$refs["cancel_" + idx].loading = true;
      await ActivityEntry.delete(row.id)
        .then((resp) => this.toggleEnabled(row))
        .catch((err) => this.onError(err))
        .then();
      this.$refs["cancel_" + idx].loading = false;
    },
    toggleEnabled(row) {
      if (!this.selected) return;
      const xs = _.get(this.selected, "row.entries", []);
      xs.map((x) => {
        if (x.id == row.id) {
          x.enabled = false;
        }
        return x;
      });
    },
    getPatientId() {
      return _.get(this.selected, "row.patient_id");
    },
    onError(err) {
      this.$message({
        type: "error",
        message: err.message,
      });
    },
    getRowStyle({ row, rowIndex }) {
      if (!row.enabled) {
        return { "text-decoration": "line-through" };
      }
    },
    onAddActivityEntry() {
      this.showCreateEntryDialog = true;
    },
    entrySubmitted(params) {
      this.$emit("entrySubmitted", params);
      this.showCreateEntryDialog = false;
    },
  },
};
</script>

<template>
  <el-dialog
    title=""
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :show-close="false"
    :destroy-on-close="true"
    width="95%"
    center
  >
    <create-activity-entry
      :params="selected"
      :patientId="getPatientId()"
      @entrySubmitted="entrySubmitted"
      :visible.sync="showCreateEntryDialog"
    ></create-activity-entry>

    <div v-if="err">
      <el-alert :title="err.message" type="error" @close="err = null">
        <pre>{{ err.errorsMap }}</pre>
      </el-alert>
    </div>
    <el-table :data="entries" :row-style="getRowStyle">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-col :span="24">
              <b>Inserimento:</b> {{ scope.row.full_creator }} - {{ scope.row.created_at_time }}
            </el-col>
            <el-col :span="24" v-if="scope.row.enabled == 0">
              <b>Annullamento:</b>
              {{ scope.row.full_remover }} - {{ scope.row.removed_at_time }}
            </el-col>
          </template>
        </el-table-column>
      <el-table-column
        property="performed_at"
        label="Data"
        width="200"
      ></el-table-column>
      <el-table-column property="name" label="Tipo Partecipazione" width="300">
        <template slot-scope="scope">
          <span>{{ getParticipation(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column property="note" label="Note"></el-table-column>
      <el-table-column label="Azioni" width="200">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            round
            :disabled="!scope.row.enabled"
            @click="handleDelete(scope.$index, scope.row)"
            :loading="loadingCancel"
            :ref="'cancel_' + scope.$index"
            >Annulla</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onAddActivityEntry()" round
        >Aggiungi</el-button
      >
      <el-button @click="$emit('onClose')" round>Chiudi</el-button>
    </span>
  </el-dialog>
</template>
