import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Therapy extends ArkiBaseService {

    static endpoint = 'therapy';

    static search(params) {
        return Api.get(`config/${this.endpoint}`, {
            params: {
                string: params
            }
        })
    }

    static update(params) {
        return Api.patch(this.endpoint, params);
    }

    static upsert(payload) {
        return Api.post(`${this.endpoint}/upsert`, payload);
    }

    static twoFactorValidation() {
        return Api.post(`${this.endpoint}/two_factor_validation`);
    }

    static needsExport(range, patient_id) {
        return axios({
            url: 'pdf/therapyneeds',
            method: 'GET',
            params: {
                range: range,
                patient_id: patient_id
            },
            responseType: 'blob',
        });
    }
}
