<template>
    <div>
        <arki-filters ref="filters" @filtered="fetch" target="drugs" :dateRangeFilter="false"
            :dateMonthRangeFilter="false" :optionsFilter="false" :professionsFilter="false" />
        <!-- Header button  -->
        <el-button-group class="export-drugs export-buttons" disabled>
            <el-tooltip class="item" effect="light" content="Export Settimanale Farmaci" placement="bottom">
                <el-button :loading="downloadLoading" label="week" type="primary" class="md-primary-dark" round
                    icon="el-icon-first-aid-kit" @click="needsExport('week')">7</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="light" content="Export Mensile Farmaci" placement="bottom">
                <el-button :loading="downloadLoading" label="month" type="primary" class="md-primary-dark" round
                    icon="el-icon-first-aid-kit" @click="needsExport('month')">30</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="light" content="Export Farmaci in Uso" placement="bottom">
                <el-button :loading="downloadLoading" label="drugs" type="primary" class="md-primary-dark" round
                    icon="el-icon-first-aid-kit" @click="needsExport('total')"></el-button>
            </el-tooltip>
        </el-button-group>
        <div class="block with-filters" style="margin-top: 50px;">
            <el-table :class="paginateTable"
                :data="Array.isArray(drugs.data) ? drugs.data.filter(data => !search || data.denominazione_confezione.toLowerCase().includes(search.toLowerCase()))
                : Object.values(drugs.data).filter(data => !search || data.denominazione_confezione.toLowerCase().includes(search.toLowerCase()))"
                :default-sort="{ prop: 'time', order: 'descending' }" style="width: 100%;" :max-height="maxHeight"
                v-loading="loading">
                <el-table-column prop="denominazione_confezione" label="Farmaco" sortable></el-table-column>
                <el-table-column prop="descrizione_gruppo_equivalenza" label="Desc. gruppo eq."></el-table-column>
                <el-table-column prop="titolare_aic" label="Titolare AIC"></el-table-column>
                <el-table-column prop="quantity" label="Qtà. mensile" width="150" align="center"></el-table-column>
                <el-table-column v-if="this.filters.patient_id" prop="dosaggio" label="Dosaggio giornaliero" width="150" align="center">
                    <template slot-scope="scope">
                        {{ _.get(_.find(patients, { farmaco: scope.row.farmaco }), 'quantity', 'N/A') }}
                    </template>
                </el-table-column>
                <el-table-column v-if="!this.filters.patient_id" prop="full_name" label="Elenco ospiti" align="center"
                    width="150">
                    <template slot-scope="scope">
                        <drugs-modal :parentScope="scope.row" :patients="patients"></drugs-modal>
                    </template>
                </el-table-column>
                <el-table-column align="center">
                    <template slot="header" slot-scope="scope">
                        <el-input v-model="search" size="mini" placeholder="Cerca per Farmaco" />
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination v-if="!search" background layout="prev, pager, next" :page-size="drugs.per_page"
                :total="drugs.total" :hide-on-single-page="true" :current-page="current_page"
                @current-change="changePage">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import ArkiFilters from "@/js/components/ArkiFilters";
import ArkFile from "@/js/helpers/file";
import Therapy from "@/js/api/services/Therapy";
import Drugs from "@/js/api/services/Drugs"
import DrugsModal from './DrugsModal.vue';

export default {
  name: "Drugs",
  components: {
    ArkiFilters,
    DrugsModal,
  },
  data() {
    return {
      loading: true,
      downloadLoading: false,
      drugs: {
        data: [],
        per_page: 1,
        total: null,
      },
      patients: [],
      filters: [],
      search: '',
      current_page: 1,
      paginateTable: null,
      // first fetch is from filter
      unchangedFilter: false,
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 235;
    },
  },
  watch: {
    drugs: function () {
      this.drugs.total / this.drugs.per_page > 1
        ? (this.paginateTable = "paginate-table")
        : (this.paginateTable = null);
    },
  },
  methods: {
    update() {
      this.unchangedFilter = true;
      if (this.$refs.filters) {
        this.$refs.filters.emit();
      }
    },
    async fetch(filters) {
        this.filters = filters;
      if (filters.patient_id != this.$route.query.patient_id) {
        this.$router.replace({
        path: this.$route.path,
        query: {
          patient_id: filters.patient_id || undefined,
          t: new Date().getTime(),
        },
      });
        return;
      }
      this.loading = true;
      if (!this.unchangedFilter) {
        this.current_page = 1;
      }
      let params = {
        patient_id: filters.patient_id,
        page: this.current_page,
      };
      const response = await Drugs.fetch(params);
      this.drugs = response.data.drugs;
      this.patients = response.data.patients;
      this.loading = false;
      // reset the checked flag
      this.unchangedFilter = false;
    },
    async needsExport(range) {
      this.downloadLoading = true;
        ArkFile.downloadFromRequest(Therapy.needsExport(range, this.filters.patient_id), "").finally(
        () => {
          this.downloadLoading = false;
        }
      );
    },
    changePage(page) {
      this.current_page = page;
      this.update();
    },
  },
};
</script>
