<template>
  <div>
    <el-timeline>
      <el-timeline-item
        v-for="(item, index) in items"
        :key="index"
        :color="item.profession ? professions[item.profession].color : null"
        :size="item.size"
        :timestamp="item.clean_date_time"
        placement="top"
      >
        <div class="timeline-icon-container" v-if="hasIcon">
          <el-button
            circle
            class="timeline-button"
            :style="
              item.profession
                ? 'background-color:' + professions[item.profession].color
                : null
            "
          >
            <font-awesome-icon :icon="icons[item.type] || 'clipboard'" />
          </el-button>
        </div>

        <el-card :class="`box-card ${handleEnabled(item.enabled)}`">
          <div slot="header" class="clearfix" v-if="item.patient">
            <span>
              <b class="md-title no-padding" v-if="item.patient">{{
                item.patient.full_name
              }}</b>
            </span>
          </div>
          <div
            v-if="action === 'Note'"
            v-html="item.description"
            style="white-space: pre-line"
          ></div>
          <el-row :gutter="20" v-else-if="action === 'Exam'">
            <el-col
              :span="12"
              v-for="value in item.values"
              :key="value.id"
              style="padding: 5px"
            >
              <b>
                <font-awesome-icon
                  class="warning-text"
                  icon="exclamation-triangle"
                  v-if="value.critical == 1"
                />
                {{ value.type_description }}:
              </b>
              {{ value.value }} {{ value.unity }} {{ value.category }}
            </el-col>
            <el-col
              :span="24"
              style="padding-top: 10px"
              v-if="item.verification_notes"
              >Nota: {{ item.verification_notes }}</el-col
            >
          </el-row>

          <div class="md-subhead text-right" style="padding-top: 10px">
            {{ item.full_creator }}
            <span v-if="item.profession && professions[item.profession]">
              ({{ professions[item.profession].label }}) -
            </span>
            {{ item.created_at_time }}

            <el-button
              :loading="loading"
              class="float-right danger-text"
              style="padding: 2px; margin-left: 10px"
              type="text"
              icon="el-icon-delete"
              v-if="
                item.enabled == 1 &&
                item.creator_id == user.id &&
                (item.type === undefined || item.type == 1)
              "
              @click="destroy(item.id, item.description)"
            />
          </div>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>
<script>
import Note from "@/js/api/services/Note";
import Exam from "@/js/api/services/Exam";
export default {
  name: "ArkiTimeline",
  props: ["items", "hasIcon", "action", "confirmDescription"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    icons: function () {
      return [
        "clipboard",
        "clipboard",
        "puzzle-piece",
        "bath",
        "tasks",
        "thermometer-half",
        "dumbbell",
        "chalkboard-teacher",
        "shield-alt",
        "calendar-times",
        "",
        "user-injured",
        "band-aid",
        "prescription-bottle-alt",
        "tablets",
        "user-shield",
        "toilet",
        "prescription-bottle-alt",
        "utensils",
        "balance-scale-right",
        "hard-hat",
        "exclamation-triangle",
      ];
    },
    professions() {
      return this.$store.state.professions;
    },
  },
  methods: {
    handleEnabled(enabled) {
      if (!enabled) return "deleted-row";
    },
    destroy(id, description) {
      let confirmMessage = "Confermi eliminazione?";
      switch (this.action) {
        case "Note":
          let newDescription = description
            .replace("<div>", "")
            .replace("</div>", "");
          confirmMessage = `Eliminare nota: "${newDescription}" ?`;
          break;
        case "Exam":
          confirmMessage = "Eliminare Rilevamento?";
          break;
      }

      this.$confirm(confirmMessage, "Attenzione", {
        confirmButtonText: "OK",
        cancelButtonText: "Annulla",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.loading = true;
          this.delete(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
    async delete(id) {
      switch (this.action) {
        case "Note":
          await Note.destroy(id);
          break;
        case "Exam":
          await Exam.destroy(id);
          break;
      }
      this.$emit("update");
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.timeline-button {
  border-radius: 40px;
  padding: 0px;
  height: 40px;
  width: 40px;
  margin-left: 9px;
  background: #f3f7fa;
}

.timeline-icon-container {
  left: -23px !important;
  top: 30px;
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  border-radius: 30px;
  line-height: 30px;
  text-align: center;
}

.md-title {
  font-weight: bold;
}
</style>
