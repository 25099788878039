<script>
import SelectActivity from "../filters/SelectActivity.vue";
import SelectParticipation from "../filters/SelectParticipation.vue";
import { ActivityEntry } from "@/js/api/services/activity";
import { extractHttpError } from "@/js/helpers";
import moment from "moment";

export default {
    components: {
        SelectActivity,
        SelectParticipation,
    },
    props: ["visible", "patient", "patientId", "params"],
    data() {
        return {
            loading: false,
            err: null,
            form: {
                note: null,
                participation_type: null,
                time: moment(new Date()).format("HH:mm:ss"),
            },
        };
    },
    computed: {
        activity() {
            return _.get(this.params, "row.activity", {});
        },
        performedAt() {
            if (!this.params) return;
            const {
                row: { year, month },
                day,
            } = this.params;
            return new Date(year, month - 1, day);
        },
        selectableTime() {
            var endTime = new moment();
            var startTime = moment(endTime).add(-4, "hours").format("HH:mm:ss");
            return startTime + " - " + endTime.format("HH:mm:ss");
        },
        rules() {
            return {
                time: [
                    { required: true, message: "Campo obbligatorio", trigger: "blur" },
                ],
                participation_type: [
                    { required: true, message: "Campo obbligatorio", trigger: "blur" },
                ],
                note: [
                    {
                        required:
                            this.form.participation_type &&
                                this.form.participation_type.code == "altro"
                                ? true
                                : false,
                        message: "Campo obbligatorio",
                        trigger: "blur",
                    },
                ],
            };
        },
    },
    methods: {
        closeDialog(submitted) {
            this.resetForm();
            this.$emit("update:visible", false);
            this.$emit("entrySubmitted", { submitted });
        },
        onSubmit() {
            this.validateForm();
        },
        warnInvalidForm() {
            return;
        },
        async validateForm() {
            if (!this.$refs.form) return;
            this.$refs.form.validate(this.handleFormValidation);
        },
        handleFormValidation(isValid) {
            if (!isValid) {
                return this.warnInvalidForm();
            }
            this.save();
        },
        async save() {
            const data = this.getPayload();
            try {
                await ActivityEntry.save({ data });
                this.closeDialog(true);
            } catch (error) {
                this.err = extractHttpError(error);
            }
        },
        getPatientId() {
            return _.get(this.patient, "id") || this.patientId;
        },
        getPayload() {
            const tags = [];
            if (this.form.participation_type) {
                tags.push(this.form.participation_type);
            }
            return {
                type: "activityEntry",
                attributes: {
                    performed_at: moment(this.performedAt).format("YYYY-MM-DD"),
                    time: this.form.time,
                    patient_id: this.getPatientId(),
                    note: this.form.note,
                    tags,
                    activity: this.activity,
                },
            };
        },
        resetForm() {
            this.err = null;
            this.$refs.form.resetFields();
        },
    },
};
</script>

<template>
    <el-dialog title="Inserimento" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :show-close="false" :destroy-on-close="true" width="70%" center>
        <div v-if="err">
            <el-alert :title="err.message" type="error" @close="err = null">
                <pre>{{ err.errorsMap }}</pre>
            </el-alert>
        </div>
        <el-form label-position="top" label-width="80px" :model="form" :rules="rules" ref="form">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item prop="activity" label="Attivitá">
                        <el-input type="text" :value="activity.label" readonly />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="Data" prop="performed_at" size="small">
                        <el-date-picker :value="performedAt" type="date" format="dd/MM/yyyy" value-format="yyyy-MM-dd" readonly></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Orario" prop="time" size="small">
                        <el-time-picker v-model="form.time" :editable="false" format="HH:mm" value-format="HH:mm:ss" :picker-options="{
                            selectableRange: selectableTime,
                        }" placeholder="Seleziona Orario">
                        </el-time-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Tipo Partecipazione" prop="participation_type" size="small" required>
                        <select-participation v-model="form.participation_type"></select-participation>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="Note" prop="note" size="small">
                        <el-input type="textarea" v-model="form.note" :rows="3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button style="margin-right: 20px" type="info" plain @click="closeDialog(false)">Annulla</el-button>
            <el-button type="success" plain @click="onSubmit" :loading="loading">Salva</el-button>
        </span>
    </el-dialog>
</template>
