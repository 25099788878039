<template>
    <div>
        <button class="el-icon-user" @click="() => {visible = true}"></button>
        <md-dialog :md-active.sync="visible" :md-clicked-outside="() => {visible = false}"
            style="width: 65vw; max-width: none; padding-right: 20px; top: 50% !important;">
            <md-dialog-title style="text-align: center">Farmaco assegnato a</md-dialog-title>
            <div style="position: absolute; right: 0">
                <md-button class="md-icon-button" @click="visible = false">
                    <md-icon>close</md-icon>
                </md-button>
            </div>
            <md-dialog-content>
                <el-table
                :data="_.filter(patients, function(patient) { return parentScope.farmaco == patient.farmaco })">
                    <el-table-column label="Ospite" prop="full_name">
                    </el-table-column>
                    <el-table-column label="Dosaggio giornaliero" prop="quantity" align="center">
                    </el-table-column>
                </el-table>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>

export default {
  name: "DrugsModal",
  props: ["parentScope", "patients"],
  data() {
    return {
      visible: false,
    };
  },
};
</script>
