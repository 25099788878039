import Api from "@/js/api/Api";

export default class ActivityEntry {
    static endpoint = "activityEntries";

    static list(params) {
        return Api.get(this.endpoint, { params }).then(({ data }) => data);
    }

    static get(id) {
        return Api.get(`${this.endpoint}/${id}`);
    }

    static save(payload) {
        return Api.post(this.endpoint, payload);
    }

    static delete(id) {
        return Api.delete(`${this.endpoint}/${id}`);
    }
}
