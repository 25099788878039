<script>
import { PatientActivity } from "@/js/api/services/activity";
import { extractHttpError } from "@/js/helpers";
import SelectActivity from "../filters/SelectActivity.vue";
import { Activity } from "@/js/api/services/activity";

export default {
  name: "CreatePatientActivity",
  components: { SelectActivity },
  props: ["visible", "patient", "patientId", "date"],
  data() {
    return {
      err: null,
      form: {
        activity: null,
      },
      rules: {
        activity: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" }
        ],
      },
      loadingSave: false,
    };
  },
  methods: {
    closeDialog(submitted) {
      this.resetForm();
      this.$emit("update:visible", false);
      this.$emit("submitted", submitted);
    },
    onSubmit() {
      this.validateForm();
    },
    warnInvalidForm() {
      return;
    },
    async validateForm() {
      if (!this.$refs.form) return;
      this.$refs.form.validate(this.handleFormValidation);
    },
    handleFormValidation(isValid) {
      if (!isValid) {
        return this.warnInvalidForm();
      }
      this.save();
    },
    async save() {
      this.loadingSave = true;
      var disabledActivity = await this.searchDisabledActivity();

      if (disabledActivity) {
        this.$notify({
          title: "Errore",
          message: "Attività disattivata dall'amministratore",
          type: "error",
        });
        this.loadingSave = false;
      } else {
        const data = this.getPayload();

        try {
          await PatientActivity.save({ data });
          this.$message({
            type: "success",
            message: "Attivitá inserita.",
          });
          this.closeDialog(true);
          this.loadingSave = false;
        } catch (error) {
          this.err = extractHttpError(error);
          this.loadingSave = false;
        }
      }
    },
    getPatientId() {
      return _.get(this.patient, "id") || this.patientId;
    },
    getPayload() {
      return {
        type: "patientActivity",
        attributes: {
          patient_id: this.getPatientId(),
          year: this.date ? this.date.year() : undefined,
          month: this.date ? this.date.month() + 1 : undefined,
          activity: {
            id: this.form.activity.id,
            code:
              this.form.activity.code ||
              _.machineName(this.form.activity.label),
            label: this.form.activity.label,
            description: this.form.activity.description,
          },
        },
      };
    },
    resetForm() {
      this.err = null;
      this.$refs.form.resetFields();
    },
    async searchDisabledActivity() {
      var params = {
        structure_id: this.$store.state.structureId,
        label: this.form.activity.label,
      };
      const respA = await Activity.adminSearchDisabledActivity(params);
      return respA.data && Object.keys(respA.data).length >= 1 ? true : false;
    },
  },
};
</script>

<template>
  <el-dialog
    title="Inserimento nuova attività"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :show-close="false"
    :destroy-on-close="true"
    width="70%"
    center
  >
    <div v-if="err">
      <el-alert :title="err.message" type="error" @close="err = null">
        <pre>{{ err.errorsMap }}</pre>
      </el-alert>
    </div>
    <el-form
      label-position="top"
      label-width="80px"
      :model="form"
      :rules="rules"
      ref="form"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="activity" label="Attivitá" required>
            <select-activity
              v-model="form.activity"
              :allowCreate="true"
            ></select-activity>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        style="margin-right: 20px"
        type="info"
        plain
        @click="closeDialog(false)"
        >Annulla</el-button
      >
      <el-button type="success" plain @click="onSubmit" :loading="loadingSave"
        >Salva</el-button
      >
    </span>
  </el-dialog>
</template>
