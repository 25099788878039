<template>
  <div style="height: 0">
    <el-badge
      :value="comunicationCounter > 0 ? comunicationCounter : null"
      class="item comunicationBadge"
      type="warning"
      style="z-index: 2; position: fixed; right: 50px; top: 16px"
    >
      <el-button
        type="info"
        icon="el-icon-chat-dot-round"
        class="menu-comunication-trigger"
        round
        @click="show()"
      ></el-button>
    </el-badge>
    <md-dialog
      :md-active.sync="showMessages"
      style="width: 98vw; max-width: none"
    >
      <md-dialog-title style="text-align: center">Messaggi</md-dialog-title>
      <md-dialog-content>
        <el-table
          :data="comunications.data"
          :default-sort="{ prop: 'time', order: 'descending' }"
          style="width: 100%"
          align="center"
          fixed="right"
        >
          <el-table-column
            prop="clean_date_time"
            label="Data e Ora"
            sortable
            align="center"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="title"
            label="Titolo"
            width="170px"
          ></el-table-column>
          <el-table-column label="Dettaglio Messaggio">
            <template slot-scope="scope">
              {{ scope.row.message }}
            </template>
          </el-table-column>
          <el-table-column prop="read_confirm" label="Conferma lettura" align="center" width="130px">
          <template slot-scope="scope">
            <button
                v-if="comunications.unread.length > 0 &&
                  comunications.unread.includes(scope.row.id) &&
                  scope.row.creator_id != user.id
                "
                @click="toggleMessage(scope.row)"
            >
                <em class="el-icon-view" />
            </button>
            <div v-else>
                <i class="el-icon-check"></i>
            </div>
          </template>
        </el-table-column>
        </el-table>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import moment from "moment";
import Comunication from "@/js/api/services/Comunication";

export default {
  name: "ArkiReadComunication",
  data() {
    return {
      showMessages: false,
      loading: true,
      comunications: {
        data: [],
        unread: [],
      },
      comunicationCounter: null,
      brodcastCheckMessage: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    show() {
      if(this.comunicationCounter > 0){
        this.showMessages = !this.showMessages;
      } else {
        if(this.$router.currentRoute.fullPath !== '/comunications'){
            this.$router.push({path: '/comunications'});
        }
      }
    },
    async fetch() {
      this.loading = true;
      const response = await Comunication.fetch();
      this.comunications.data = _.filter(response.data.comunication.data, (communication) => {
        return this.comunications.unread.includes(communication.id) && communication.enabled === 1;
      });
      this.loading = false;
    },
    async toggleMessage(row) {
      let params = {
        userId: this.user.id,
      };
      await Comunication.markAsSeen(row.id, params)
        .catch((error) => {
          return false;
        })
        .finally(() => {
          this.comunications.unread.splice(
            this.comunications.unread.indexOf(row.id),
            1
          );
        });
    },
    async showUnreadComunication() {
      var oldComunicationCounter = this.comunicationCounter;
      const response = await Comunication.count();
      this.comunications.unread = response.data;
      this.comunicationCounter = response.data.length;
      if (
        response.data.length > 0 &&
        response.data.length >= oldComunicationCounter
      ) {
        this.$notify.info({
          title: "Nuove Comunicazioni",
          message:
            "Hai " + this.comunicationCounter + " comunicazioni da leggere!",
          duration: 0,
        });
      }
      // attiva il broadcasting per evitare doppie notifiche
      if (!this.brodcastCheckMessage) this.brodcastCheckMessage = true;
    },
  },
  mounted() {
    this.showUnreadComunication();
    this.fetch();

    this.$echo
      .channel(`arkicare-activity.${this.$store.state.departmentId}`)
      .listen(".comunication.created", (e) => {
        if (this.brodcastCheckMessage) {
          this.showUnreadComunication();
          this.fetch();
        }
      });
  },
};
</script>
<style>
.comunicationBadge sup.el-badge__content.el-badge__content--warning.is-fixed {
  z-index: 10;
}
</style>
